import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-new-orleans-louisiana.png'
import image0 from "../../images/cities/scale-model-of-house-of-dance-&-feathers-in-new-orleans-louisiana.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='New Orleans'
            state='Louisiana'
            image={image}
            lat='30.0534'
            lon='-89.9345'
            attractions={ [{"name": "House of Dance & Feathers", "vicinity": "1317 Tupelo St, New Orleans", "types": ["point_of_interest", "establishment"], "lat": 29.9621384, "lng": -90.0098236}] }
            attractionImages={ {"House of Dance & Feathers":image0,} }
       />);
  }
}